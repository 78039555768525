<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/repair-report/customer/page"
    >
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','维保班组')" prop="teamName">
            <vm-autocomplete
              :filter.sync="filter.teamName"

              url="organization/team/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="队长姓名">
            <el-input v-model.trim="filter.teamLeaderName" clearable></el-input>
          </vm-search>
          <vm-search label="队长电话">
            <el-input v-model.trim="filter.teamLeaderPhone" clearable></el-input>
          </vm-search>
          <vm-search label="工单编号">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>
          <vm-search label="工单状态">
            <vm-dict-select v-model="filter.orderStatus" type="repairOrderStatus"></vm-dict-select>
          </vm-search>

          <vm-search label="报修来源">
            <vm-dict-select v-model="filter.source" type="repairSource"></vm-dict-select>
          </vm-search>
          <vm-search label="报修时间">
            <el-date-picker v-model.trim="filter.reportedTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
          </vm-search>
          <vm-search label="报修人姓名">
            <el-input v-model.trim="filter.reporterName" clearable></el-input>
          </vm-search>
          <vm-search label="报修人电话">
            <el-input v-model.trim="filter.reporterPhone" clearable></el-input>
          </vm-search>
          <vm-search label="使用单位">
            <vm-autocomplete
              :filter.sync="filter.usingCompanyName"
              url="customer-relationship/customer-company/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼盘">
            <vm-autocomplete
              :filter.sync="filter.realEstateName"
              url="real-estate/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼宇">
            <vm-autocomplete
              :filter.sync="filter.buildingName"
              url="real-estate/building/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="内部编号">
            <el-input v-model.trim="filter.elevatorName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="品牌">
            <vm-autocomplete
              :filter.sync="filter.brandName"
              url="basic/elevator-brand/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="型号">
            <vm-autocomplete
              :filter.sync="filter.modelName"
              url="basic/elevator-model/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="是否滑梯">
            <el-select v-model="filter.elevatorFalling">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="是否停梯">
            <el-select v-model="filter.elevatorStopped">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="是否困人">
            <el-select v-model="filter.peopleTrapped">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>

      <el-table-column
        align="center"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="assigneeName" label="工单责任人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.memberName}}/{{scope.row.memberPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号">
      </el-table-column>
      <el-table-column prop="orderStatusDesc" label="工单状态">
      </el-table-column>
      <el-table-column prop="sourceDesc" label="报修来源">
      </el-table-column>
      <el-table-column prop="reportedTime" label="报修时间" align="center"></el-table-column>
      <el-table-column prop="reporterName" label="报修人/电话">
        <template slot-scope="scope">
          {{scope.row.reporterName}}/{{scope.row.reporterPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="usingCompanyName" label="使用单位" align="center"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇" align="center">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center"></el-table-column>
      <el-table-column prop="elevatorName" label="内部编号" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌/型号" align="center">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorFalling" label="是否滑梯" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorFalling?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorStopped" label="是否停梯" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorStopped?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="peopleTrapped" label="是否困人" align="center">
        <template slot-scope="scope">
          {{scope.row.peopleTrapped?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <el-button v-if="$auth('客户维修上报管理详情')" type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <repair-verification ref="verificationPage" @save-success="getList(-1)"></repair-verification>
    <customer-add ref="customerAdd" @save-success="getList(-1)"></customer-add>
    <repair-cancel ref="cancelPage" @save-success="getList(-1)"></repair-cancel>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "../reportOrder/RepairDetailNew.vue";
  import RepairVerification from "@/views/repair/RepairVerification";
  import RepairCancel from "@/views/repair/RepairReportCancel";
  import CustomerAdd from "./CustomerAdd";
  import VmAutocomplete from "@/components/VmAutocomplete";

  export default {
    components: {EditPage,RepairVerification,RepairCancel,CustomerAdd,VmAutocomplete},
    data() {
      return {
        filter: {
          name: "",
          teamName: "",
          customerName: "",
          wbManagerName: "",
          orderStatus: "",
          orderColumnName:"reportedTime",
        },
        statistics:{},
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      toTask(row) {
        const arr = [];
        if (row.tasks instanceof Array) {
          row.tasks.forEach(item=>{
            arr.push(item.id);
          });
        }

        this.$http.post("/maintenance/repair-order/from-task",arr).then(()=>{
          this.$message.success("已转任务");
        });
      },
      deleteRow(row) {
        this.$confirm("确定删除该条记录吗?", this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/repair-report/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success("该条记录" + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
